.modal-90w-90h {
    height: 90%!important;
    width: 90%!important;
    max-height: 90%!important;
    max-width: 90%!important;
}

.card-header-tabs{
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: none!important;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6!important;
}

.modal-content {
    height: 100%;
}

.modal {
    z-index: 1061!important;
}

.popover {
    box-shadow: 5px 5px 10px 3px rgba(0,0,0,0.4);
}

.noScrollBar::-webkit-scrollbar {
    display: none;
  }

.btn-group::-webkit-scrollbar {
    display: none;
  }

body {
    overflow: hidden;
}

.input-group-append {
    z-index: 0;
}

.my-custom-react-select__menu {
    z-index: 200!important
}